exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-brands-js": () => import("./../../../src/pages/brands.js" /* webpackChunkName: "component---src-pages-brands-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-study-js": () => import("./../../../src/templates/study.js" /* webpackChunkName: "component---src-templates-study-js" */)
}

